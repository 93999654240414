<template>
  <div id="login">
    <div class="login-header">
      Interchange Login
    </div>
    <form @submit.prevent="login()" novalidate>
      <div class="login-body">
        <div class="error-message" v-if="message"><b>{{ message }}</b></div>
        <div class="field">
          <input v-model="email" type="email" class="input" placeholder="Email" />
        </div>
        <div class="field">
          <input v-model="password" type="password" class="input" placeholder="Password" />
        </div>
      </div>
      <div class="login-footer">
        <button class="button is-primary">Login</button>
      </div>
    </form>
  </div>
</template>

<script>
  import api from 'shared/json-api'
  export default {
    data () {
      return {
        email: '',
        password: '',
        message: null
      }
    },
    methods: {
      login () {
        this.message = ''
        return api.create('session', {
          email: this.email,
          password: this.password
        }).then(response => {
          const user = response.data
          this.$store.commit('authenticate', user)
          window.location.href = user.role === 'driver' ? '/driver' : '/'
        }).catch(error => {
          this.password = null
          this.message = 'Login failed.'
        })
      }
    }
  }
</script>

<style scoped>
  #login {
    width: 100%;
    max-width: 320px;
    margin: 15% auto;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 50px 100px rgba(50,50,93,.1), 0 15px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1);
    max-width: 370px;
  }
  .error-message {
    display: block;
    color: #fff;
    background: #ff3434;
    padding: 5px 10px;
    margin: 0 0 10px;
    border-radius: 4px;

  }
  .login-header {
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    background: #eef5fb;
    color: #6c879f;
    font-weight: bold;
    border-radius: 4px 4px 0 0;
  }
  .login-body {
    padding: 30px 20px;
  }
  .login-footer button {
    margin: 0;
    display: block;
    width: 100%;
    height: auto;
    font-size: 16px;
    border-radius: 0 0 4px 4px;
    line-height: 2rem;
  }
</style>
