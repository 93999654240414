/* eslint no-console: 0 */

import Vue from 'shared/init'
import App from '../../sessions/app.vue'
import store from 'shared/store'

import '../../interchange/styles.scss'

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    store,
    render: h => h(App)
  }).$mount('#session-form')
})
